import React, { useEffect, useState, useRef } from "react";
import unsplash from "../api/unsplash";

import {
  Box,
  IconButton,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Card,
  Grid,
  CardContent,
  Zoom,
  Autocomplete,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { Add, Delete, Edit, Close, Check } from "@mui/icons-material";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import GlobalSnackbar from "./Snackbar";

import moment from "moment-timezone";
import { TipologiePrestazioni } from "./TipologiePrestazioni";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

const ExtraMedici = (props) => {
  const [mediciExtra, setExtraMedici] = useState([]);
  const [tipologiePrestazioni, setTipologiePrestazioni] = useState([]);
  const [mediciCliente, setMediciCliente] = useState([]);
  const [sedi, setSedi] = useState([]);
  const [mediciExtraVisibili, setExtraMediciVisibili] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      width: 100,
      headerAlign: "center",
      align: "center",
      sort: "asc",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <ExtraMediciPopUp
            myFunction={handleSaveData}
            title="Modifica Medico Extra"
            mediciExtra={mediciExtra}
            setSnack={setSnack}
            setOpenSnack={setOpenSnack}
            operation="Edit"
            medicoExtraSel={params.row}
            tipologiePrestazioni={tipologiePrestazioni}
            mediciCliente={mediciCliente}
            sedi={sedi}
          />
          <DeleteDialog medicoExtra={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "Descrizione",
      headerName: "Medico",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "NomeSede",
      headerName: "Sede",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "NomeTipologiaPrestazione",
      headerName: "Tipologia Prestazione",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>{params.row.Data && moment(params.row.Data).format("DD-MM-YYYY")}</>
      ),
    },
    {
      field: "InizioViaggio",
      headerName: "Dalle",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "FineViaggio",
      headerName: "Alle",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const reloadData = async () => {
    let response = await unsplash.get("mediciExtra", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        id: props.idAzienda,
      },
    });
    setExtraMedici(response.data.data);
    setExtraMediciVisibili(response.data.data);
  };
  const handleSaveData = async (medicoExtra, operazione) => {
    try {
      //AGGIUNGERE LA CREAZIONE DELLA SEDE

      if (operazione === "new") {
        await unsplash.post(
          "mediciExtra",
          { medicoExtra: { ...medicoExtra, Cliente: props.idAzienda } },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Medico Extra creato con successo",
        });
        setOpenSnack(true);
        reloadData();
      } else {
        await unsplash.put(
          "mediciExtra",
          { medicoExtra: medicoExtra },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Medico Extra modificato con successo",
        });
        setOpenSnack(true);
        reloadData();
      }
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la creazione del Medico Extra",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await unsplash.get("mediciExtra", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: {
            id: props.idAzienda,
          },
        });
        setExtraMedici(response.data.data);
        setExtraMediciVisibili(response.data.data);
        //let mediciEsistentiIds = response.data.data?.map((el) => el?.Medico);
        setLoading(false);

        let responseTipologiePrestazioni = await unsplash.get(
          "tipologiePrestazioni",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setTipologiePrestazioni(responseTipologiePrestazioni.data.data);

        let responseMediciCliente = await unsplash.get("medici", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        /* const filteredMedici = responseMediciCliente.data.data?.filter((obj) =>
          !mediciEsistentiIds.includes(obj.ID)
        ); */
        setMediciCliente(responseMediciCliente.data.data);

        let responseSedi = await unsplash.get("sedi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.idAzienda },
        });
        setSedi(responseSedi.data.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (ID) => {
    try {
      await unsplash.delete("mediciExtra", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: ID,
        },
      });

      reloadData();

      setSnack({
        severity: "success",
        messaggio: "Medico Extra eliminato con successo",
      });
      setOpenSnack(true);
    } catch (error) {
      setSnack({
        severity: "error",
        messaggio: "Errore durante la cancellazione",
      });
      setOpenSnack(true);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <ExtraMediciPopUp
          myFunction={handleSaveData}
          title="Crea nuovo Medico Extra"
          mediciExtra={mediciExtra}
          setSnack={setSnack}
          setOpenSnack={setOpenSnack}
          operation="new"
          tipologiePrestazioni={tipologiePrestazioni}
          mediciCliente={mediciCliente}
          sedi={sedi}
        />
      </Grid>
      <Box
        sx={{
          height: { xs: "75vh", md: "60vh", xl: "75vh" },
        }}
      >
        <DataGridPro
          initialState={{ pinnedColumns: { left: ["Actions"] } }}
          sx={{ fontSize: 12 }}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterPersonalized,
          }}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={mediciExtraVisibili}
          columns={columns}
          getRowId={(row) => row.ID}
          //pageSize={100}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

const ExtraMediciPopUp = (props) => {
  const [open, setOpen] = useState(false);
  const [medicoExtra, setMedicoExtra] = useState(null);
  const [categoriaSel, setCategoriaSel] = useState(null);
  const [medicoSel, setMedicoSel] = useState(null);
  const [sedeSel, setSedeSel] = useState(null);
  const [data, setData] = useState(null);
  const [dalle, setDalle] = useState(null);
  const [alle, setAlle] = useState(null);
  const handleClickOpen = () => {
    if (props.operation === "new") {
      setMedicoExtra({
        Medico: null,
        Sede: null,
        TipologiaPrestazione: null,
        Data: null,
        InizioViaggio: null,
        FineViaggio: null,
        Chiamata: 0,
      });
      setCategoriaSel(null);
    } else {
      setMedicoExtra(props.medicoExtraSel);
      setDalle(
        moment(new Date()).format("YYYY-MM-DD") +
          " " +
          props.medicoExtraSel.InizioViaggio
      );

      setAlle(
        moment(new Date()).format("YYYY-MM-DD") +
          " " +
          props.medicoExtraSel.FineViaggio
      );
      setData(moment(props.medicoExtraSel.Data).format("YYYY-MM-DD"));
      setCategoriaSel(
        props.tipologiePrestazioni?.filter(
          (c) => c.ID === props.medicoExtraSel.TipologiaPrestazione
        )[0]
      );
      setMedicoSel(
        props.mediciCliente?.filter(
          (c) => c.ID === props.medicoExtraSel.Medico
        )[0]
      );
      setSedeSel(
        props.sedi?.filter((c) => c.ID === props.medicoExtraSel.Sede)[0]
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMedicoExtra({
      Medico: null,
      Sede: null,
      TipologiaPrestazione: null,
      Data: null,
      InizioViaggio: null,
      FineViaggio: null,
      Chiamata: 0,
    });
    setDalle(null);

    setAlle(null);
    setData(null);
    setCategoriaSel(null);
    setMedicoSel(null);
    setSedeSel(null);
  };

  const handleSave = async () => {
    props.myFunction(
      {
        ...medicoExtra,
        Data: medicoExtra?.Data
          ? moment(medicoExtra.Data).format("YYYY-MM-DD")
          : null,
      },
      props.operation
    );
  };

  return (
    <div>
      {props.operation === "Edit" ? (
        <IconButton aria-label="edit" onClick={handleClickOpen} sx={{ p: 0 }}>
          <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          onClick={handleClickOpen}
          id={props.id}
          sx={{ color: "white" }}
          variant="outlined"
          className="ButtonMiller"
        >
          Aggiungi
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ mx: "auto" }}>{props.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={medicoSel}
                id="combo-box-demo"
                options={props.mediciCliente}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                fullWidth
                onChange={(event, newValue) => {
                  setMedicoSel(newValue);
                  let d = { ...medicoExtra };
                  d.Medico = newValue.ID;
                  setMedicoExtra(d);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medico" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="combo-box-demo"
                options={props.sedi}
                fullWidth
                value={sedeSel}
                getOptionLabel={(option) => option.Descrizione}
                onChange={(event, newValue) => {
                  setSedeSel(newValue);
                  if (newValue) {
                    let a = { ...medicoExtra };
                    a.Sede = newValue.ID;
                    setMedicoExtra(a);
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Sede" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="combo-box-demo"
                options={props.tipologiePrestazioni}
                fullWidth
                value={categoriaSel}
                getOptionLabel={(option) => option.Descrizione}
                onChange={(event, newValue) => {
                  setCategoriaSel(newValue);
                  if (newValue) {
                    let a = { ...medicoExtra };
                    a.TipologiaPrestazione = newValue.ID;
                    setMedicoExtra(a);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tipologia" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Data"
                  sx={{ width: "100%" }}
                  value={moment(data)}
                  onChange={(newValue) => {
                    let r = { ...medicoExtra };
                    setData(newValue);
                    if (newValue) {
                      r.Data = newValue.format("YYYY-MM-DD");
                    }
                    setMedicoExtra(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  //disabled={medicoExtra?.Chiamata === 1}
                  label="Dalle"
                  value={moment(dalle)}
                  onChange={(newValue) => {
                    let r = { ...medicoExtra };
                    setDalle(newValue);
                    if (newValue) {
                      r.InizioViaggio = newValue.format("HH:mm:ss");
                    }
                    setMedicoExtra(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Alle"
                  //disabled={medicoExtra?.Chiamata === 1}
                  value={moment(alle)}
                  onChange={(newValue) => {
                    let r = { ...medicoExtra };
                    setAlle(newValue);
                    if (newValue) {
                      r.FineViaggio = newValue.format("HH:mm:ss");
                    }
                    setMedicoExtra(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", height: "100%" }}
                control={
                  <Checkbox
                    name="Chiamata"
                    checked={medicoExtra?.Chiamata === 1}
                    onChange={() => {
                      //setDalle(null);
                      //setAlle(null);
                      setMedicoExtra({
                        ...medicoExtra,
                        FineViaggio: null,
                        InizioViaggio: null,
                        Chiamata: medicoExtra?.Chiamata === 1 ? 0 : 1,
                      });
                    }}
                  />
                }
                label="Chiamata"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            onClick={handleClose}
          >
            Chiudi
          </Button>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Check />}
            onClick={() => {
              handleSave();
              handleClose();
            }}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExtraMedici;

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare l'medicoExtra selezionato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare il seguente medicoExtra :
            <b>{props.medicoExtra.Descrizione}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.medicoExtra.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
