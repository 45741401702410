import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import unsplash from "../api/unsplash";
import Avatar from "@mui/material/Avatar";
import GlobalSnackbar from "./Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";

import {
  Grid,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CardActionArea,
  CardMedia,
  Divider,
  CardActions,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  Autocomplete,
  Checkbox,
  Zoom,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";

import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { IconButton } from "@mui/material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";

import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";

import {
  Add,
  Delete,
  Close,
  Check,
  Search,
  MenuBook,
  FiberNew,
  Circle,
  AccountCircle,
  AttachFile,
  Edit,
} from "@mui/icons-material";

import moment from "moment";

import {
  LocalizationProvider,
  DatePicker,
  PickersDay,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

export const RiepilogativoFormazione = () => {
  const [riepilogativiFormazione, setRiepilogativiFormazione] = useState([]);
  const [loading, setLoading] = useState(true);
  const [riepilogativoSel, setRiepilogativoSel] = useState([]);
  const [anagraficaCorsi, setAnagraficaCorsi] = useState([]);
  const [formatori, setFormatori] = useState([]);
  const [value, setValue] = useState(0);
  const [clienti, setClienti] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [periodo, setPeriodo] = useState(new Date());
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const loadRiepilogativo = async (periodoSel, c) => {
    try {
      let response = await unsplash.get("riepilogativiFormazione", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          mese: moment(periodoSel).format("MM"),
          anno: moment(periodoSel).format("YYYY"),
        },
      });
      setRiepilogativiFormazione(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      hide: false,
      width: "130",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <NewRiepilogativo
            id="edit"
            clienti={clienti}
            Corsi={anagraficaCorsi}
            Formatori={formatori}
            riepilogativo={params.row}
            saveFunction={handleSave}
            title="Modifica Riepilogativo"
            tipologia={params.row.Tipo === 1 ? "aula" : "video"}
          />
          <DeleteDialog riepilogativo={params.row} myFunction={handleDelete} />
        </>
      ),
    },
    {
      field: "DataInizio",
      headerName: "Data Inizio",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.DataInizio) {
          return (
            <div>{moment(params.row.DataInizio).format("DD/MM/YYYY")}</div>
          );
        }
      },
    },
    {
      field: "DataFine",
      headerName: "Data Fine",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.DataFine) {
          return <div>{moment(params.row.DataFine).format("DD/MM/YYYY")}</div>;
        }
      },
    },
    {
      field: "Corso",
      headerName: "Corso",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Durata",
      headerName: "Durata",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DescrizioneAzienda",
      headerName: "Azienda",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DescrizionePrimoFormatore",
      headerName: "Primo Formatore",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DescrizionePartecipante",
      headerName: "Partecipante",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoteFatturazione",
      headerName: "Note Fatturazione",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Costo",
      headerName: "Costo",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Stato",
      headerName: "Stato",
      width: 70,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.Stato === 1 ? (
            <Tooltip title="Attivo">
              <Circle
                sx={{ color: "#40C18F", width: "15px", height: "15px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Disattivo">
              <Circle
                sx={{ color: "#FFC897", width: "15px", height: "15px" }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "NumeroFattura",
      headerName: "Numero Fattura",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (riepilogativo, operazione, tipologia) => {
    let riepilog = { ...riepilogativo };

    if (operazione === "new") {
      riepilog.Tipologia = tipologia === "aula" ? 1 : 2;

      try {
        riepilog.DataInizio = riepilog.DataInizio
          ? moment(riepilog.DataInizio).format("YYYY-MM-DD")
          : null;
        riepilog.DataFine = riepilog.DataFine
          ? moment(riepilog.DataFine).format("YYYY-MM-DD")
          : null;
        await unsplash.post(
          "riepilogativiFormazione",
          {
            riepilogativo: riepilog,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Riepilogativo creato con successo",
        });
        setOpenSnack(true);
        setPeriodo(new Date());
        loadRiepilogativo(new Date());
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante la creazione",
        });
        setOpenSnack(true);
      }
      handleClose();
    } else {
      riepilog.DataInizio = riepilog.DataInizio
        ? moment(riepilog.DataInizio).format("YYYY-MM-DD")
        : null;
      riepilog.DataFine = riepilog.DataFine
        ? moment(riepilog.DataFine).format("YYYY-MM-DD")
        : null;

      try {
        await unsplash.put(
          "riepilogativiFormazione",
          { riepilogativo: riepilog },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Riepilogativo aggiornato con successo",
        });
        setOpenSnack(true);
        setPeriodo(new Date());
        loadRiepilogativo(new Date());
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggiornamento",
        });
        setOpenSnack(true);
      }
      handleClose();
    }
  };

  const handleDelete = async (idRiepilogativo) => {
    try {
      await unsplash.delete("riepilogativiFormazione", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: idRiepilogativo,
        },
      });

      setSnack({
        severity: "success",
        messaggio: "Riepilogativo eliminato con successo",
      });
      setOpenSnack(true);
      loadRiepilogativo(new Date());
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'eliminazione del Riepilogativo",
      });
      setOpenSnack(true);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <GridToolbarQuickFilter placeholder="Cerca..." />
        </Grid>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      loadRiepilogativo(new Date());
      let responsecorsi = await unsplash.get("anagraficaCorsi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setAnagraficaCorsi(responsecorsi.data.data);

      let responseFormatori = await unsplash.get("formatori", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const formatoriAttvi = responseFormatori.data.data.filter(
        (a) => a.Attivo === 1
      );
      setFormatori(formatoriAttvi);

      setFormatori(formatoriAttvi);
      let responseClienti = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setClienti(responseClienti.data.data);
    })();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: { xs: 0 } }}>
            <Grid item xs={12}>
              <Card>
                <CardContent
                  style={{
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    paddingTop: 0,
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      textColor="inherit"
                      variant="scrollable"
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<MeetingRoomIcon />}
                        iconPosition="start"
                        label="Aula"
                      />
                      <Tab
                        icon={<VideoCameraFrontIcon />}
                        iconPosition="start"
                        label="Videoconferenza"
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={6} md={8}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DatePicker
                            ampmInClock={false}
                            views={["month", "year"]}
                            value={moment(periodo)}
                            renderInput={(props) => (
                              <TextField size="small" fullWidth {...props} />
                            )}
                            label="Periodo"
                            onChange={(newValue) => {
                              setPeriodo(newValue);
                              loadRiepilogativo(newValue, false);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <NewRiepilogativo
                          id="new"
                          Corsi={anagraficaCorsi}
                          clienti={clienti}
                          riepilogativo={riepilogativoSel}
                          Formatori={formatori}
                          saveFunction={handleSave}
                          title="Nuovo Riepilogativo"
                          tipologia="aula"
                          snack={snack}
                          openSnack={openSnack}
                          handleCloseSnack={handleCloseSnack}
                          setSnack={setSnack}
                          setOpenSnack={setOpenSnack}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        height: { xs: "75vh", md: "60vh", xl: "65vh" },
                        width: 1,
                      }}
                    >
                      <DataGridPro
                        initialState={{
                          pinnedColumns: { left: ["Actions"] },
                        }}
                        components={{
                          Toolbar: CustomToolbar,
                          Footer: CustomFooterPersonalized,
                        }}
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowHeight={52}
                        rows={riepilogativiFormazione?.filter(
                          (el) => el.Tipologia === 1
                        )}
                        columns={columns}
                        getRowId={(row) => row.ID}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Grid
                      fullWidth
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={6} md={8}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DatePicker
                            ampmInClock={false}
                            views={["month", "year"]}
                            value={moment(periodo)}
                            renderInput={(props) => (
                              <TextField size="small" fullWidth {...props} />
                            )}
                            label="Periodo"
                            onChange={(newValue) => {
                              setPeriodo(newValue);
                              loadRiepilogativo(newValue, false);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <NewRiepilogativo
                          id="new"
                          Corsi={anagraficaCorsi}
                          clienti={clienti}
                          riepilogativo={riepilogativoSel}
                          Formatori={formatori}
                          saveFunction={handleSave}
                          title="Nuovo Riepilogativo"
                          tipologia="video"
                          snack={snack}
                          openSnack={openSnack}
                          handleCloseSnack={handleCloseSnack}
                          setSnack={setSnack}
                          setOpenSnack={setOpenSnack}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      sx={{
                        height: { xs: "75vh", md: "60vh", xl: "65vh" },
                        width: 1,
                      }}
                    >
                      <DataGridPro
                        initialState={{
                          pinnedColumns: { left: ["Actions"] },
                        }}
                        components={{
                          Toolbar: CustomToolbar,
                          Footer: CustomFooterPersonalized,
                        }}
                        slots={{
                          loadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                        localeText={
                          itIT.components.MuiDataGrid.defaultProps.localeText
                        }
                        rowHeight={52}
                        rows={riepilogativiFormazione?.filter(
                          (el) => el.Tipologia === 2
                        )}
                        columns={columns}
                        getRowId={(row) => row.ID}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                      />
                    </Box>
                  </TabPanel>
                </CardContent>
              </Card>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/* Creazione Nuovi Formatori */
const NewRiepilogativo = (props) => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formatore, setFormatore] = useState(null);
  const [formatore2, setFormatore2] = useState(null);
  const [lavoratoreSel, setLavoratoreSel] = useState(false);
  const [formatore3, setFormatore3] = useState(null);
  const [lavoratori, setLavoratori] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [clienteSel, setClienteSel] = useState(null);
  const [codiceCorso, setCodiceCorso] = useState(null);
  const [riepilogativo, setRiepilogativo] = useState({
    DataInizio: null,
    DataFine: null,
    Tipologia: 0,
    Corso: 0,
    Durata: 0,
    Azienda: 0,
    PrimoFormatore: 0,
    SecondoFormatore: 0,
    TerzoFormatore: 0,
    Partecipante: 0,
    NoteFatturazione: 0,
    Costo: 0,
    Stato: 0,
    NumeroFattura: "",
    CentriDiCosto: "",
    Aggiornamento: 0,
  });

  const handleClickOpen = () => {
    if (props.id === "edit") {
      setRiepilogativo(props.riepilogativo);
      setCodiceCorso(
        props.Corsi.filter((p) => p.Codice === props.riepilogativo.Corso)[0]
      );
      setFormatore(
        props.Formatori.filter(
          (p) => p.ID === props.riepilogativo.PrimoFormatore
        )[0]
      );
      setFormatore2(
        props.Formatori.filter(
          (p) => p.ID === props.riepilogativo.SecondoFormatore
        )[0]
      );
      setFormatore3(
        props.Formatori.filter(
          (p) => p.ID === props.riepilogativo.TerzoFormatore
        )[0]
      );
      setClienti(props.clienti);
      let cliente = props.clienti.filter(
        (m) => m.ID === props.riepilogativo.Azienda
      );
      setClienteSel(cliente ? cliente[0] : null);
      loadLavoratori(props.riepilogativo.Azienda);
    } else {
      setRiepilogativo({
        DataInizio: null,
        DataFine: null,
        Tipologia: 0,
        Corso: 0,
        Durata: 0,
        Azienda: 0,
        PrimoFormatore: 0,
        SecondoFormatore: 0,
        TerzoFormatore: 0,
        Partecipante: 0,
        NoteFatturazione: 0,
        Costo: 0,
        Stato: 0,
        NumeroFattura: "",
        CentriDiCosto: "",
        Aggiornamento: 0,
      });
      setClienti(props.clienti);
      setClienteSel(null);
      setCodiceCorso(null);
      setFormatore(null);
      setFormatore2(null);
      setFormatore3(null);
      setLavoratoreSel(null);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    let copiaRiepilogativo = { ...riepilogativo };
    copiaRiepilogativo[e.target.name] = e.target.value;
    setRiepilogativo(copiaRiepilogativo);
  };
  const handleSave = async () => {
    if (!riepilogativo.DataInizio) {
      props.setSnack({
        severity: "error",
        messaggio: "Selezionare la Data di inizio",
      });
      props.setOpenSnack(true);
    } else {
      props.saveFunction(riepilogativo, props.id, props.tipologia);
      handleClose();
    }
  };
  const loadLavoratori = async (id) => {
    let response = await unsplash.get("lavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { formazione: id },
    });
    setLavoratori(response.data.data);
    setLavoratoreSel(
      response.data.data?.filter(
        (p) => p.ID === props.riepilogativo.Partecipante
      )[0]
    );
  };
  return (
    <>
      <IconButton
        style={{ display: props.id !== "edit" ? "None" : "" }}
        aria-label="edit"
        onClick={handleClickOpen}
        id={props.id}
      >
        <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
      </IconButton>

      <Button
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        sx={{
          color: "white",
          display: props.id === "edit" ? "None" : "",
        }}
        variant="outlined"
        className="ButtonMiller"
      >
        Aggiungi
      </Button>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="xl"
          TransitionComponent={Transition}
        >
          {/* Title Container */}
          <DialogTitle
            sx={{
              mx: "auto",
            }}
          >
            {props.title}
          </DialogTitle>
          {/* Body Container */}
          <DialogContent>
            <Grid container marginTop={2} spacing={1}>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    ampmInClock={false}
                    renderInput={(props) => <TextField {...props} fullWidth />}
                    label="Data Inizio"
                    value={moment(riepilogativo.DataInizio)}
                    onChange={(newValue) => {
                      let riep = { ...riepilogativo };
                      riep.DataInizio = newValue
                        ? newValue.format("YYYY-MM-DD")
                        : null;
                      setRiepilogativo(riep);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    ampmInClock={false}
                    renderInput={(props) => <TextField {...props} fullWidth />}
                    label="Data Fine"
                    value={moment(riepilogativo.DataFine)}
                    onChange={(newValue) => {
                      let riep = { ...riepilogativo };
                      riep.DataFine = newValue
                        ? newValue.format("YYYY-MM-DD")
                        : null;
                      setRiepilogativo(riep);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6} display="flex" alignItems="center">
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      name="Aggiornamento"
                      checked={riepilogativo.Aggiornamento === 1 ? true : false}
                      onChange={(e) => {
                        let riep = { ...riepilogativo };
                        riep.Aggiornamento = e.target.checked ? 1 : 0;
                        setRiepilogativo(riep);
                      }}
                    />
                  }
                  label="Aggiornamento"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={props.Corsi}
                  getOptionLabel={(option) =>
                    option.Codice + " - " + option.Descrizione
                  }
                  value={codiceCorso}
                  fullWidth
                  onChange={(event, newValue) => {
                    let c = { ...riepilogativo };
                    c.Corso = newValue.Codice ? newValue.Codice : null;
                    setRiepilogativo(c);
                    setCodiceCorso(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Corso" />
                  )}
                  disabled={props.id === "edit"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={clienti}
                  value={clienteSel}
                  getOptionLabel={(option) => option.RagioneSociale}
                  fullWidth
                  onChange={(event, newValue) => {
                    setClienteSel(newValue);
                    let r = { ...riepilogativo };
                    r.Azienda = newValue ? newValue.ID : null;
                    setRiepilogativo(r);
                    if (newValue) {
                      loadLavoratori(newValue.ID);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Azienda" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={lavoratori}
                  getOptionLabel={(option) =>
                    option.Cognome + " " + option.Nome
                  }
                  fullWidth
                  value={lavoratoreSel}
                  onChange={(event, newValue) => {
                    setLavoratoreSel(newValue);
                    let r = { ...riepilogativo };
                    r.Partecipante = newValue ? newValue.ID : null;
                    setRiepilogativo(r);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Partecipante" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={props.Formatori}
                  getOptionLabel={(option) =>
                    option.Nome + " " + option.Cognome
                  }
                  value={formatore}
                  fullWidth
                  onChange={(event, newValue) => {
                    let c = { ...riepilogativo };
                    c.PrimoFormatore = newValue.ID ? newValue.ID : null;
                    setRiepilogativo(c);
                    setFormatore(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Formatore 1" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={props.Formatori}
                  getOptionLabel={(option) =>
                    option.Nome + " " + option.Cognome
                  }
                  value={formatore2}
                  fullWidth
                  onChange={(event, newValue) => {
                    let c = { ...riepilogativo };
                    c.SecondoFormatore = newValue.ID ? newValue.ID : null;
                    setRiepilogativo(c);
                    setFormatore2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Formatore 2" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={props.Formatori}
                  getOptionLabel={(option) =>
                    option.Nome + " " + option.Cognome
                  }
                  value={formatore3}
                  fullWidth
                  onChange={(event, newValue) => {
                    let c = { ...riepilogativo };
                    c.TerzoFormatore = newValue.ID ? newValue.ID : null;
                    setRiepilogativo(c);
                    setFormatore3(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Formatore 3" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={riepilogativo.Durata}
                  id="outlined-basic"
                  label="Durata"
                  name="Durata"
                  onChange={handleChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={riepilogativo.NoteFatturazione}
                  id="outlined-basic"
                  label="NoteFatturazione"
                  name="NoteFatturazione"
                  onChange={handleChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={riepilogativo.Costo}
                  id="outlined-basic"
                  label="Costo"
                  name="Costo"
                  onChange={handleChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={riepilogativo.NumeroFattura}
                  id="outlined-basic"
                  label="NumeroFattura"
                  name="NumeroFattura"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Stato</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={riepilogativo.Stato}
                    label="Stato"
                    onChange={(e) => {
                      let riep = { ...riepilogativo };
                      riep.Stato = e.target.value;
                      setRiepilogativo(riep);
                    }}
                  >
                    <MenuItem value={0}>In attesa</MenuItem>
                    <MenuItem value={1}>Confermato</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* Bottoni Salva & Esci */}
          </DialogContent>
          <DialogActions
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mx: 2,
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
            >
              Chiudi
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
            >
              Salva
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};
const DeleteDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mx: "auto" }}>
          Sei sicuro di voler eliminare la riepilogativo selezionata?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare la seguente riepilogativo
            :
            <br />
            <b>
              {moment(props.riepilogativo.DataInizio).format("YYYY-MM-DD")} -{" "}
              {props.riepilogativo.Corso}
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={() => props.myFunction(props.riepilogativo.ID)}>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
