import React, { useState, useEffect } from "react";
import unsplash from "../api/unsplash";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../utils/CustomFooterPersonalized";
import moment from "moment-timezone";
import {
  Delete,
  Info,
  Close,
  DoneAll,
  ArrowForward,
  Check,
  Add,
  FilterList,
  FilterListOff,
  FlashOn,
  FlashOff,
  Edit,
  CheckCircle,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Autocomplete,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Dialog,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  Zoom,
  Tooltip,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import GlobalSnackbar from "./Snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom timeout={2000} in={true} ref={ref} {...props} />;
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <GridToolbarQuickFilter placeholder="Cerca..." />
      </Grid>
    </GridToolbarContainer>
  );
}

export const RiepilogativoVisite = () => {
  const [riepilogativoVisite, setRiepilogativoVisite] = useState([]);
  const [loading, setLoading] = useState(true);
  const [riepilogativoVisiteBackup, setRiepilogativoVisiteBackup] = useState(
    []
  );
  const [periodo, setPeriodo] = useState(new Date());
  const [medici, setMedici] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [lavoratoriAssociati, setLavoratoriAssociati] = useState([]);
  const [idRigaSave, setIdRigaSave] = useState([]);
  const [tipologiaVisita, setTipologiaVisita] = useState([]);
  const [esiti, setEsiti] = useState([]);
  const [filtroAttivo, setFiltroAttivo] = useState(false);
  const [caricaTutto, setCaricaTutto] = useState(false);
  const [filtroEtichette, setFiltroEtichette] = useState({
    Cliente: "",
    Medico: "",
    Sede: "",
    Fattura: "",
    Status: "",
    Data: "",
    Riunioni: "",
  });

  const columnsRiepilogativo = [
    {
      filterable: false,
      field: "Actions",
      headerName: "Azioni",
      width: 150,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={async () => {
              await setRowSel(params.row);
              document.getElementById("editRiepilogativo").click();
            }}
          >
            <Edit style={{ height: "2rem", width: "2rem", color: "#2265B1" }} />
          </IconButton>
          <DeleteDialog
            idRiga={params.row.ID}
            loadRiepilogativo={loadRiepilogativo}
            periodo={periodo}
            //setOpen={setOpen}
          />
        </>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 70,
      renderCell: (params) => {
        if (
          params.row.Data &&
          moment(params.row.Data).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
        ) {
          if (params.row.Status === "Annullata") {
            return (
              <Tooltip title="Annullata">
                <CancelIcon sx={{ color: "#ff2768" }} />
              </Tooltip>
            );
          } else if (params.row.Status === "Confermata") {
            return (
              <Tooltip title="Confermata">
                <CheckCircle sx={{ color: "#40c18f" }} />
              </Tooltip>
            );
          } else if (params.row.Status === "AttesaConferma") {
            return (
              <Tooltip title="Attesa Conferma">
                <ErrorIcon sx={{ color: "#FFC897" }} />
              </Tooltip>
            );
          }
        } else {
          if (params.row.Status === "Annullata") {
            return (
              <Tooltip title="Annullata Scaduta">
                <CancelIcon sx={{ color: "gray" }} />
              </Tooltip>
            );
          } else if (params.row.Status === "Confermata") {
            return (
              <Tooltip title="Confermata Scaduta">
                <CheckCircle sx={{ color: "gray" }} />
              </Tooltip>
            );
          } else if (params.row.Status === "AttesaConferma") {
            return (
              <Tooltip title="Attesa Conferma Scaduta">
                <ErrorIcon sx={{ color: "gray" }} />
              </Tooltip>
            );
          }
        }
      },
      /* cellClassName: (params) => {
        return params.row.Data &&
          moment(params.row.Data).format("DD/MM/YYYY") >
            moment(new Date()).format("DD/MM/YYYY")
          ? params.row.Status
          : "";
      }, */
    },
    {
      field: "Data",
      headerName: "Data",
      width: 150,
      renderCell: (params) => {
        if (params.row.Data) {
          return <div>{moment(params.row.Data).format("DD/MM/YYYY")}</div>;
        }
      },
    },
    {
      field: "Dalle",
      headerName: "Dalle",
      width: 70,
      renderCell: (params) => {
        if (params.row.Dalle) {
          const dalle = params.row.Dalle.split(":");
          return <div>{params.row.Dalle ? `${dalle[0]}:${dalle[1]}` : ""}</div>;
        }
      },
    },
    {
      field: "Alle",
      headerName: "Alle",
      width: 70,
      renderCell: (params) => {
        if (params.row.Alle) {
          const alle = params.row.Alle.split(":");
          return <div>{params.row.Alle ? `${alle[0]}:${alle[1]}` : ""}</div>;
        }
      },
    },
    { field: "NomeMedico", headerName: "Nome Medico", width: 150 },
    { field: "RagioneSociale", headerName: "Ragione Sociale", width: 150 },
    { field: "NomeSede", headerName: "Nome Sede", width: 150 },
    {
      field: "Nvisite",
      headerName: "N° visite",
      width: 150,
      renderCell: (params) => {
        if (params.row.Nvisite) {
          return (
            <Tooltip
              onMouseOver={() => loadLavoratoriAssociati(params.row.ID)}
              title={lavoratoriAssociati.map((l) => (
                <Typography sx={{ p: 1 }}>
                  {l.Cognome + " " + l.Nome}
                </Typography>
              ))}
            >
              <div style={{ cursor: "pointer" }}>{params.row.Nvisite}</div>
            </Tooltip>
          );
        }
      },
    },
    { field: "Location", headerName: "Location", width: 150 },

    { field: "InvioIdoneita", headerName: "Invio Idoneita", width: 150 },
    { field: "Riunioni", headerName: "Riunioni", width: 150 },
    { field: "NFattura", headerName: "N° Fattura", width: 150 },
    { field: "KilometraggioMedico", headerName: "Km Medico", width: 150,
      valueGetter: (params) => {
        let orario = params.row?.Orario ? params.row?.Orario?.split(":") : "00"
        let kmMedico= params.row?.KilometraggioMedico === 1 ? `${params.row?.Km}km - ${orario[0]}:${orario[1]}h` : ""
        return kmMedico
      },
      renderCell: (params) => {
        let orario = params.row?.Orario ? params.row?.Orario?.split(":") : "00"
        let kmMedico= params.row?.KilometraggioMedico === 1 ? `${params.row?.Km}km - ${orario[0]}:${orario[1]}h` : ""
        return kmMedico
      }
    },
    { field: "PersonaleMedico", headerName: "Personale Medico", width: 150,
      valueGetter: (params) => {
        let dalle = params.row?.DallePM ? params.row?.DallePM?.split(":") : "00"
        let alle = params.row?.AllePM ? params.row?.AllePM?.split(":") : "00"
        let kmPersonale= params.row?.PersonaleMedico === 1 ? `${params.row?.KmInfermiere}km (${dalle[0]}:${dalle[1]}-${alle[0]}:${alle[1]})` : ""
        return kmPersonale
      },
      renderCell: (params) => {
        let dalle = params.row?.DallePM ? params.row?.DallePM?.split(":") : "00"
        let alle = params.row?.AllePM ? params.row?.AllePM?.split(":") : "00"
        let kmPersonale= params.row?.PersonaleMedico === 1 ? `${params.row?.KmInfermiere}km (${dalle[0]}:${dalle[1]}-${alle[0]}:${alle[1]})` : ""
        return kmPersonale
      }
    },
  ];

  const [rowSel, setRowSel] = useState();

  useEffect(() => {
    async function fetchData() {
      let responseEsiti = await unsplash.get("esiti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setEsiti(responseEsiti.data.data);

      let responseMedici = await unsplash.get("medici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setMedici(responseMedici.data.data?.filter((el) => el?.Attivo === 1));

      let responseClienti = await unsplash.get("clienti", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      setClienti(responseClienti.data.data);
      let responseTipologiaVisita = await unsplash.get("tipologiaVisita", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTipologiaVisita(responseTipologiaVisita.data.data);

      let responseRiepilogativo = await unsplash.get("riepilogativoVisite", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          mese: moment(new Date()).format("MM"),
          anno: moment(new Date()).format("YYYY"),
          caricaTutto: 0,
        },
      });

      setRiepilogativoVisite(responseRiepilogativo.data.data);
      setRiepilogativoVisiteBackup(responseRiepilogativo.data.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const loadRiepilogativo = async (periodoSel, c) => {
    let responseRiepilogativo = await unsplash.get("riepilogativoVisite", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        mese: moment(periodoSel).format("MM"),
        anno: moment(periodoSel).format("YYYY"),
        caricaTutto: c ? 1 : 0,
      },
    });

    let resp = responseRiepilogativo.data.data;

    if (filtroEtichette.Cliente != "") {
      resp = resp.filter((r) =>
        r.RagioneSociale.toLowerCase().includes(
          filtroEtichette.Cliente.toLowerCase()
        )
      );
    }

    if (filtroEtichette.Sede != "") {
      resp = resp.filter((r) =>
        r.NomeSede.toLowerCase().includes(filtroEtichette.Sede.toLowerCase())
      );
    }

    if (filtroEtichette.Fattura != "") {
      resp = resp.filter((r) =>
        r.NFattura.toLowerCase().includes(filtroEtichette.Fattura.toLowerCase())
      );
    }

    if (filtroEtichette.Status != "") {
      resp = resp.filter((r) =>
        r.Status.toLowerCase().includes(filtroEtichette.Status.toLowerCase())
      );
    }

    if (filtroEtichette.Data != "") {
      resp = resp.filter((r) =>
        moment(r.Data)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(filtroEtichette.Data.toLowerCase())
      );
    }

    if (filtroEtichette.Medico != "") {
      resp = resp.filter((r) =>
        r.NomeMedico.toLowerCase().includes(
          filtroEtichette.Medico.toLowerCase()
        )
      );
    }
    if (filtroEtichette.Riunioni != "") {
      resp = resp.filter((r) =>
        r.Riunioni.toLowerCase().includes(
          filtroEtichette.Riunioni.toLowerCase()
        )
      );
    }

    setRiepilogativoVisite(resp);

    setRiepilogativoVisiteBackup(responseRiepilogativo.data.data);
  };

  const loadLavoratoriAssociati = async (idRiga) => {
    if (idRiga !== idRigaSave) {
      let responseRiepilogativo = await unsplash.get(
        "riepilogativoLavoratori",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: idRiga },
        }
      );

      setLavoratoriAssociati(responseRiepilogativo.data.data);
      setIdRigaSave(idRiga);
    }
  };

  return (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <OpenDialog
              id={"editRiepilogativo"}
              row={rowSel}
              medici={medici}
              clienti={clienti}
              periodo={periodo}
              loadRiepilogativo={loadRiepilogativo}
              tipologiaVisita={tipologiaVisita}
            />
            <OpenDialog
              id={"newRiepilogativo"}
              medici={medici}
              clienti={clienti}
              periodo={periodo}
              loadRiepilogativo={loadRiepilogativo}
              tipologiaVisita={tipologiaVisita}
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disabled={caricaTutto}
                  ampmInClock={false}
                  views={["month", "year"]}
                  value={moment(periodo)}
                  renderInput={(props) => (
                    <TextField size="small" fullWidth {...props} />
                  )}
                  label="Periodo"
                  onChange={(newValue) => {
                    setPeriodo(newValue);
                    loadRiepilogativo(newValue, false);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", flexWrap: "nowrap" }}
            >
              <IconButton
                aria-label="filtra"
                onClick={() => {
                  setRiepilogativoVisite(riepilogativoVisiteBackup);
                  setFiltroEtichette({
                    Cliente: "",
                    Medico: "",
                    Sede: "",
                    Fattura: "",
                    Status: "",
                    Data: "",
                    Riunioni: "",
                  });
                  setFiltroAttivo(!filtroAttivo);
                }}
              >
                {filtroAttivo ? (
                  <FilterListOff sx={{ color: "#11526F" }} />
                ) : (
                  <FilterList sx={{ color: "#11526F" }} />
                )}
              </IconButton>
              <IconButton
                aria-label="filtra"
                onClick={() => {
                  loadRiepilogativo(periodo, !caricaTutto);
                  setCaricaTutto(!caricaTutto);
                }}
              >
                {caricaTutto ? (
                  <FlashOn sx={{ color: "#11526F" }} />
                ) : (
                  <FlashOff sx={{ color: "#11526F" }} />
                )}
              </IconButton>
            </Grid>
            <Box
              sx={{
                height: { xs: "75vh", md: "60vh", xl: "75vh" },
                width: "100%",
                /* "& .Annullata": {
                  backgroundColor: "#ff2768",
                  color: "#ffffff",
                },
                "& .Confermata": {
                  backgroundColor: "#40c18f",
                  color: "#ffffff",
                },
                "& .DaProgrammare": {
                  backgroundColor: "#8e75ac",
                  color: "#ffffff",
                }, */
              }}
            >
              <DataGridPro
                initialState={{ pinnedColumns: { left: ["Actions"] } }}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterPersonalized,
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading={loading}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={riepilogativoVisite}
                getRowId={(lav) => lav.ID}
                columns={columnsRiepilogativo}
                disableSelectionOnClick
              />
            </Box>
            {/* {filtroAttivo ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Medico"
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Medico");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Cliente"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Cliente");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Sede"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Sede");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Numero Fattura"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Fattura");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Status"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Status");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Data"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Data");
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      size="small"
                      label="Riunioni"
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        filtraTutto(e, "Riunioni");
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 650 }}>
                  <Table
                    sx={{ minWidth: "100%" }}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow sx={{ background: "blue!important" }}>
                        <TableCell className="headerTableCustom">
                          Data
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Dalle
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Alle
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Medico
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Cliente
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Sede/Punto vendita
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          N° visite{" "}
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Location
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Status
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Invio idoneità e medico
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Riunioni e sopralluoghi
                        </TableCell>
                        <TableCell className="headerTableCustom">
                          Numero fattura
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {riepilogativoVisite.map((r) => (
                        <TableRow
                          key={r.ID}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onDoubleClick={async () => {
                            await setRowSel(r);
                            document
                              .getElementById("editRiepilogativo")
                              .click();
                          }}
                          id="tableRowMIller"
                        >
                          <TableCell component="th" scope="row">
                            {moment(r.Data).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            {r.Dalle
                              ? moment(
                                  new Date("1970-01-01T" + r.Dalle)
                                ).format("HH:mm")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {r.Alle
                              ? moment(new Date("1970-01-01T" + r.Alle)).format(
                                  "HH:mm"
                                )
                              : ""}
                          </TableCell>
                          <TableCell>{r.NomeMedico}</TableCell>
                          <TableCell>{r.RagioneSociale}</TableCell>
                          <TableCell>{r.NomeSede}</TableCell>
                          <TableCell
                            onMouseOver={() => loadLavoratoriAssociati(r.ID)}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            {r.Nvisite}
                          </TableCell>
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                              display:
                                lavoratoriAssociati.length > 0 ? "" : "None",
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            {lavoratoriAssociati.map((l) => (
                              <p style={{ padding: "10px" }}>
                                {l.Cognome + " " + l.Nome}
                              </p>
                            ))}
                          </Popover>
                          <TableCell>{r.Location}</TableCell>
                          <TableCell
                            className={
                              r.Data &&
                              moment(r.Data).format("DD/MM/YYYY") >
                                moment(new Date()).format("DD/MM/YYYY")
                                ? "tab" + r.Status
                                : ""
                            }
                          >
                            {r.Status}
                          </TableCell>
                          <TableCell>{r.InvioIdoneita}</TableCell>
                          <TableCell>{r.Riunioni}</TableCell>
                          <TableCell>{r.NFattura}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const OpenDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [riepilogoRiga, setRiepilogoRiga] = useState({
    Data: null,
    Dalle: null,
    Alle: null,
    Medico: null,
    Cliente: null,
    Sede: null,
    PersonaleMedico: 0,
    KmInfermiere: 0,
    KilometraggioMedico: 0,
    Orario: null,
    DallePM: null,
    AllePM: null,
    Nvisite: "",
    Location: "",
    Status: "",
    InvioIdoneita: "",
    Riunioni: "",
    NFattura: "",
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [sedi, setSedi] = useState([]);
  const [medici, setMedici] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [lavoratori, setLavoratori] = useState([]);
  const [lavoratoriAssociati, setLavoratoriAssociati] = useState([]);
  const [medicoSel, setMedicoSel] = useState(null);
  const [clienteSel, setClienteSel] = useState(null);
  const [sedeSel, setSedeSel] = useState(null);
  const [medicoKm, setMedicoKm] = useState({ Km: 0, Orario: null });
  const [idRiga, setIdRiga] = useState(null);
  const [lavoratoriDaVisitare, setLavoratoriDaVisitare] = useState([]);

  const [dalle, setDalle] = useState(null);
  const [alle, setAlle] = useState(null);

  const [dallePM, setDallePM] = useState(null);
  const [allePM, setAllePM] = useState(null);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleClickOpen = async () => {
    setMedici(props.medici);
    setClienti(props.clienti);
    if (props.id === "editRiepilogativo") {
      setDalle(moment(new Date()).format("YYYY-MM-DD") + " " + props.row.Dalle);

      setAlle(moment(new Date()).format("YYYY-MM-DD") + " " + props.row.Alle);
      setDallePM(
        moment(new Date()).format("YYYY-MM-DD") + " " + props.row?.DallePM
      );

      setAllePM(
        moment(new Date()).format("YYYY-MM-DD") + " " + props.row?.AllePM
      );
      setIdRiga(props.row.ID);
      let med = props.medici.filter((m) => m.ID === parseInt(props.row.Medico));
      let cliente = props.clienti.filter(
        (m) => m.ID === parseInt(props.row.Cliente)
      );
      setClienteSel(cliente ? cliente[0] : null);
      setMedicoSel(med ? med[0] : null);
      loadLavoratori(props.row.Cliente);
      let responseSedi = await unsplash.get("sedi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: { id: props.row.Cliente },
      });
      setSedi(responseSedi.data.data);

      if (responseSedi.data.data) {
        let sed = responseSedi.data.data.filter(
          (m) => m.ID === parseInt(props.row.Sede)
        );
        setSedeSel(sed ? sed[0] : null);
      } else {
        setDalle(null);
        setSedeSel(null);
      }
      if (cliente[0]?.Gruppo === "Gavio") {
        try {
          let response = await unsplash.get("mediciKm", {
            params: {
              sede: props.row?.Sede,
              medico: props.row?.Medico,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          });
          setMedicoKm(response.data.data[0]);
        } catch (error) {
          console.error(error.message);
        }
      }

      setRiepilogoRiga(props.row);

      let responseRiepilogativo = await unsplash.get(
        "riepilogativoLavoratori",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { id: props.row.ID },
        }
      );

      setLavoratoriAssociati(responseRiepilogativo.data.data);
      setLavoratoriDaVisitare(
        responseRiepilogativo.data.data.filter((r) => !r.IDVisita)
      );
    } else {
      setLavoratoriDaVisitare([]);
      setLavoratoriAssociati([]);
      setClienteSel(null);
      setMedicoSel(null);
      setSedeSel(null);
      setSedi([]);
      setIdRiga(null);
      setRiepilogoRiga({
        Data: null,
        Dalle: null,
        Alle: null,
        Medico: null,
        Cliente: null,
        Sede: null,
        PersonaleMedico: 0,
        KmInfermiere: 0,
        KilometraggioMedico: 0,
        Orario: null,
        DallePM: null,
        AllePM: null,
        Nvisite: "",
        Location: "",
        Status: "",
        InvioIdoneita: "",
        Riunioni: "",
        NFattura: "",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      if (idRiga) {
        await unsplash.put(
          "riepilogativoVisite",
          { riepilogo: riepilogoRiga },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSnack({
          severity: "success",
          messaggio: "Visita modificata con successo",
        });
        setOpenSnack(true);
      } else {
        let response = await unsplash.post(
          "riepilogativoVisite",
          { riepilogo: riepilogoRiga },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setIdRiga(response.data.message.insertId);

        setSnack({
          severity: "success",
          messaggio: "Visita creata con successo",
        });
        setOpenSnack(true);
      }

      props.loadRiepilogativo(props.periodo, false);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore durante l'inserimento",
      });
      setOpenSnack(true);
    }
  };

  const handleChange = (e) => {
    let r = { ...riepilogoRiga };
    r[e.target.name] = e.target.value;
    setRiepilogoRiga(r);
  };

  const loadSedi = async (id) => {
    let responseSedi = await unsplash.get("sedi", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: id, riepilogativo: true },
    });
    setSedi(responseSedi.data.data);
  };

  const loadLavoratori = (id) => {
    let response = unsplash.get("lavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: id },
    });
    setLavoratori(response);
  };

  const handleMedicoAssociato = async (idMedico) => {
    if (medicoSel) {
      let response = await unsplash.get("medici", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        params: {
          relClienti: idMedico,
        },
      });
      setClienti(response.data.data);
    } else {
    }
  };
  return (
    <>
      <DialogVisiteMediche
        lavoratori={lavoratoriAssociati}
        riepilogoRiga={riepilogoRiga}
        tipologiaVisita={props.tipologiaVisita}
      />
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        aria-label="edit"
        startIcon={<Add />}
        onClick={handleClickOpen}
        id={props.id}
        sx={{ display: props.id === "editRiepilogativo" ? "None" : "" }}
        className="ButtonMiller"
        variant="contained"
      >
        Aggiungi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">Modifica Riga</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Data"
                  value={moment(riepilogoRiga.Data)}
                  onChange={(newValue) => {
                    let r = { ...riepilogoRiga };
                    r.Data = newValue
                      ? moment(newValue).format("YYYY-MM-DD")
                      : null;
                    setRiepilogoRiga(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  ampmInClock={false}
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Dalle"
                  value={moment(dalle)}
                  onChange={(newValue) => {
                    let r = { ...riepilogoRiga };
                    setDalle(newValue);
                    if (newValue) {
                      r.Dalle = newValue.format("HH:mm:ss");
                    }
                    setRiepilogoRiga(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <TimePicker
                  renderInput={(props) => <TextField fullWidth {...props} />}
                  label="Alle"
                  value={moment(alle)}
                  onChange={(newValue) => {
                    let r = { ...riepilogoRiga };
                    setAlle(newValue);
                    if (newValue) {
                      r.Alle = newValue.format("HH:mm:ss");
                    }
                    setRiepilogoRiga(r);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box-demo"
                options={medici}
                value={medicoSel}
                getOptionLabel={(option) => option.Nome + " " + option.Cognome}
                fullWidth
                onChange={(event, newValue) => {
                  setMedicoSel(newValue);
                  let r = { ...riepilogoRiga };
                  r.Medico = newValue ? newValue.ID : null;
                  setRiepilogoRiga(r);
                  handleMedicoAssociato(newValue ? newValue.ID : null);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Medico" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box-demo"
                options={clienti}
                value={clienteSel}
                getOptionLabel={(option) => option.RagioneSociale}
                fullWidth
                onChange={async (event, newValue) => {
                  setClienteSel(newValue);
                  let r = { ...riepilogoRiga };
                  r.Cliente = newValue ? newValue.ID : null;
                  setRiepilogoRiga(r);

                  if (newValue) {
                    loadSedi(newValue.ID);
                    loadLavoratori(newValue.ID);
                  } else {
                    setSedi([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Cliente" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box-demo"
                options={sedi}
                getOptionLabel={(option) => option.Descrizione}
                fullWidth
                value={sedeSel}
                onChange={async (event, newValue) => {
                  setSedeSel(newValue);
                  let r = { ...riepilogoRiga };
                  r.Sede = newValue ? newValue.ID : null;
                  setRiepilogoRiga(r);
                  if (clienteSel?.Gruppo === "Gavio" && riepilogoRiga?.Medico) {
                    try {
                      let response = await unsplash.get("mediciKm", {
                        params: {
                          sede: newValue?.ID,
                          medico: riepilogoRiga?.Medico,
                        },
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "jwt"
                          )}`,
                        },
                      });
                      setMedicoKm(response.data.data[0]);
                    } catch (error) {
                      console.error(error.message);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Sede" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={riepilogoRiga.Location}
                margin="dense"
                name="Location"
                label="Location"
                type="text"
                fullWidth
                sx={{ marginTop: "0px" }}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            {clienteSel?.Gruppo === "Gavio" && riepilogoRiga.Sede && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                      <Checkbox
                        name="KilometraggioMedico"
                        checked={riepilogoRiga?.KilometraggioMedico === 1}
                        onChange={() =>
                          setRiepilogoRiga({
                            ...riepilogoRiga,
                            KilometraggioMedico:
                              riepilogoRiga.KilometraggioMedico === 1 ? 0 : 1,
                          })
                        }
                      />
                    }
                    label="Kilometraggio Medico"
                  />
                </Grid>
                {riepilogoRiga?.KilometraggioMedico === 1 && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        disabled
                        margin="dense"
                        value={medicoKm?.Km ? medicoKm?.Km : ""}
                        label="Medici Km"
                        type="number"
                        fullWidth
                        sx={{ marginTop: "0px" }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          disabled
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField fullWidth {...props} />
                          )}
                          label="Orario"
                          value={moment(
                            medicoKm?.Orario
                              ? moment(new Date()).format("YYYY-MM-DD") +
                                  " " +
                                  medicoKm?.Orario
                              : null
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                      <Checkbox
                        name="PersonaleMedico"
                        checked={riepilogoRiga?.PersonaleMedico === 1}
                        onChange={() =>
                          setRiepilogoRiga({
                            ...riepilogoRiga,
                            PersonaleMedico:
                              riepilogoRiga?.PersonaleMedico === 1 ? 0 : 1,
                          })
                        }
                      />
                    }
                    label="Personale Medico"
                  />
                </Grid>
                {riepilogoRiga?.PersonaleMedico === 1 && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        value={riepilogoRiga?.KmInfermiere}
                        label="Km Infermiere"
                        type="number"
                        name="KmInfermiere"
                        fullWidth
                        sx={{ marginTop: "0px" }}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField fullWidth {...props} />
                          )}
                          label="Dalle"
                          value={moment(dallePM)}
                          onChange={(newValue) => {
                            let r = { ...riepilogoRiga };
                            setDallePM(newValue);
                            if (newValue) {
                              r.DallePM = newValue.format("HH:mm:ss");
                            }
                            setRiepilogoRiga(r);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <TimePicker
                          renderInput={(props) => (
                            <TextField fullWidth {...props} />
                          )}
                          label="Alle"
                          value={moment(allePM)}
                          onChange={(newValue) => {
                            let r = { ...riepilogoRiga };
                            setAllePM(newValue);
                            if (newValue) {
                              r.AllePM = newValue.format("HH:mm:ss");
                            }
                            setRiepilogoRiga(r);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item xs={idRiga ? 5 : 6}>
              <TextField
                value={riepilogoRiga.Nvisite}
                margin="dense"
                name="Nvisite"
                label="N visite"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                sx={{ marginTop: "0px" }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                margin: "auto 0",
                marginTop: 1,
                display: idRiga ? "" : "None",
              }}
            >
              <LavoratoriPopUp lavoratori={lavoratori} idRiga={idRiga} />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Status</InputLabel>
                <Select
                  value={riepilogoRiga.Status}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  onChange={(e) => {
                    let r = { ...riepilogoRiga };
                    r.Status = e.target.value;
                    setRiepilogoRiga(r);
                  }}
                  input={<OutlinedInput label="Name" />}
                >
                  <MenuItem value="Annullata">Annullata</MenuItem>
                  <MenuItem value="Confermata">Confermata</MenuItem>

                  <MenuItem value="DaProgrammare">Da Programmare</MenuItem>

                  <MenuItem value="DaTenere">Da Tenere</MenuItem>

                  <MenuItem value="AttesaConferma">
                    In Attesa Di Conferma
                  </MenuItem>

                  <MenuItem value="Sollecitata">Sollecitata</MenuItem>
                  <MenuItem value="Spostato">Spostato</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={riepilogoRiga.Riunioni}
                margin="dense"
                name="Riunioni"
                label="Riunioni"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange}
                sx={{ marginTop: "0px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={riepilogoRiga.InvioIdoneita}
                margin="dense"
                name="InvioIdoneita"
                label="Invio Idoneità"
                type="text"
                fullWidth
                variant="outlined"
                sx={{ marginTop: "0px" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={riepilogoRiga.NFattura}
                margin="dense"
                name="NFattura"
                label="N Fattura"
                type="text"
                fullWidth
                variant="outlined"
                sx={{ marginTop: "0px" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} my={"auto"}>
              <Button
                sx={{
                  display:
                    lavoratoriDaVisitare.length > 0 &&
                    riepilogoRiga.Status === "Confermata"
                      ? ""
                      : "None",
                }}
                //className="buttonGlowing"
                size="large"
                variant="contained"
                fullWidth
                onClick={() => document.getElementById("myDialog").click()}
              >
                Genera Visite
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          {/* {props.id === "editRiepilogativo" ? (
            <DeleteDialog
              idRiga={idRiga}
              loadRiepilogativo={props.loadRiepilogativo}
              periodo={props.periodo}
              setOpen={setOpen}
            />
          ) : (
            <></>
          )} */}
          <Button
            startIcon={<Close />}
            onClick={handleClose}
            className="ButtonMiller"
            variant="contained"
          >
            Chiudi
          </Button>
          <Button
            startIcon={<Check />}
            onClick={handleSave}
            className="ButtonMiller"
            variant="contained"
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const LavoratoriPopUp = (props) => {
  const columns = [
    {
      filterable: false,
      field: "Actions",
      headerName: "",
      width: 70,
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <IconButton
          aria-label="edit"
          onClick={() => {
            handleDelete(params.row.ID);
          }}
        >
          <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
        </IconButton>
      ),
    },
    { field: "Cognome", headerName: "Cognome", flex: 1 },
    { field: "Nome", headerName: "Nome", flex: 1 },
  ];

  const [open, setOpen] = useState(false);
  const [lavoratori, setLavoratori] = useState([]);
  const [lavoratoreSel, setLavoratoreSel] = useState(false);
  const [lavoratoriAssociati, setLavoratoriAssociati] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleAdd = () => {
    let arr = [...lavoratoriAssociati];
    arr.push(lavoratoreSel);
    setLavoratoriAssociati(arr);
    setLavoratoreSel(null);
    saveInsert(lavoratoreSel.ID);
  };

  const saveInsert = async (id) => {
    await unsplash.post(
      "riepilogativoLavoratori",
      { idL: id, idR: props.idRiga },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );

    let responseRiepilogativo = await unsplash.get("riepilogativoLavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idRiga },
    });

    setLavoratoriAssociati(responseRiepilogativo.data.data);
  };

  const handleDelete = async (id) => {
    await unsplash.delete("riepilogativoLavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      data: {
        id: id,
      },
    });

    let responseRiepilogativo = await unsplash.get("riepilogativoLavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idRiga },
    });

    setLavoratoriAssociati(responseRiepilogativo.data.data);
  };

  const handleClickOpen = async () => {
    document.body.style.cursor = "wait";
    setLavoratoriAssociati([]);
    setLavoratoreSel(null);
    let lav = await props.lavoratori;
    setLavoratori(
      lav.data.data.filter(
        (v) =>
          !v.DataTermine ||
          moment(v.DataTermine).format("YYYY-MM-DD") >
            moment(new Date()).format("YYYY-MM-DD")
      )
    );

    let responseRiepilogativo = await unsplash.get("riepilogativoLavoratori", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: { id: props.idRiga },
    });

    setLavoratoriAssociati(responseRiepilogativo.data.data);
    setLoading(false);
    document.body.style.cursor = "default";

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        variant="contained"
        size="small"
        id="Info"
        onClick={handleClickOpen}
      >
        <Info sx={{ color: "#11526F" }} />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Lavoratori Associati</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display="flex" alignItems="center">
            <Grid item xs={8} marginTop={1}>
              <Autocomplete
                id="combo-box-demo"
                options={getDifference(
                  lavoratori,
                  lavoratoriAssociati,
                  "ID",
                  "ID"
                )}
                getOptionLabel={(option) => option.Cognome + " " + option.Nome}
                fullWidth
                value={lavoratoreSel}
                onChange={(event, newValue) => {
                  setLavoratoreSel(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Lavoratore" />
                )}
              />
            </Grid>
            <Grid item xs={2} sx={{ display: lavoratoreSel ? "" : "None" }}>
              <Button
                variant="contained"
                className="ButtonMiller"
                onClick={handleAdd}
                startIcon={<Add />}
              >
                AGGIUNGI
              </Button>
            </Grid>
          </Grid>

          <div style={{ height: 400, width: "100%" }}>
            <DataGridPro
              initialState={{ pinnedColumns: { left: ["Actions"] } }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterPersonalized,
              }}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              loading={loading}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rows={lavoratoriAssociati}
              getRowId={(lav) => lav.ID}
              columns={columns}
              disableSelectionOnClick
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="ButtonMiller"
            startIcon={<Close />}
            sx={{ marginRight: "auto", marginLeft: 2 }}
            onClick={handleClose}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
function getDifference(array1, array2, prop1, prop2) {
  //riutilizzabile per differenze tra due array di oggetti
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[prop1] === object2[prop2];
    });
  });
}

const DeleteDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = async () => {
    await unsplash.delete("riepilogativoVisite", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      data: {
        id: props.idRiga,
      },
    });

    props.loadRiepilogativo(props.periodo);
    setOpen(false);
    //props.setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <Delete style={{ height: "2rem", width: "2rem", color: "#FF2768" }} />
      </IconButton>
      {/* <Button
        aria-label="edit"
        onClick={handleClickOpen}
        className="ButtonMillerError"
        variant="contained"
        startIcon={<Delete />}
      >
        Elimina
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">Eliminazione Visita</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attenzione, sei sicuro di voler eliminare questa Visita?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Button onClick={handleClose}>annulla</Button>
          <Button onClick={handleDelete}>Conferma</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogVisiteMediche = (props) => {
  const [medicoSel, setMedicoSel] = useState("");
  const [esitoSel, setEsitoSel] = useState("");
  const [luogoSel, setLuogoSel] = useState("");
  const [noteSel, setNoteSel] = useState("");
  const [tipologiaSel, setTipologiaSel] = useState("");
  const [dataSel, setDataSel] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [accertamenti, setAccertamenti] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [required, setRequired] = useState({
    medico: false,
    data: false,
    tipo: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const columns = [
    { field: "Descrizione", headerName: "Accertamento", width: 300 },
    {
      field: "Scadenza",
      headerName: "Scadenza",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.Scadenza
          ? moment(params.row.Scadenza).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "Importo",
      headerName: "Importo €",
      type: "number",
      width: 120,
      editable: true,
      renderCell: (params) => (params.row.Importo ? params.row.Importo : 0),
    },
    {
      field: "ImportoPassivo",
      headerName: "ImportoPassivo €",
      type: "number",
      width: 150,
      editable: true,
      renderCell: (params) =>
        params.row.ImportoPassivo ? params.row.ImportoPassivo : 0,
    },
  ];

  const loadVisitaLavoratore = async (val) => {
    let responseAccertamenti = await unsplash.get("accertamenti", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      params: {
        cliente: props.cliente,
        ultimaVisita: props.lavoratori[val]?.IDUltimaVisita
          ? props.lavoratori[val].IDUltimaVisita
          : 0,
      },
    });
    setAccertamenti(responseAccertamenti.data.data);
    setLoading(false);
    setSelectionModel(() =>
      responseAccertamenti.data.data.filter((a) => a.Presente > 0)
    );
  };

  const handleClickOpen = async () => {
    let data =
      moment(props.riepilogoRiga.Data).format("YYYY-MM-DD") +
      " " +
      moment(props.riepilogoRiga.Dalle).format("HH:mm");
    await setRequired({ medico: false, data: false, tipo: false });
    loadVisitaLavoratore(0);
    setActiveStep(0);
    setDataSel(data);
    setOpen(true);
    await setMedicoSel("");
    await setEsitoSel("");
    await setLuogoSel("");
    await setNoteSel("");
    await setTipologiaSel("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNext = async () => {
    //qua faccio le operazioni ogni volta
    let newSkipped = skipped;

    if (activeStep + 1 < props.lavoratori.length) {
      loadVisitaLavoratore(activeStep + 1);
    }
    await setRequired({ medico: false, data: false, tipo: false });
    //await setDataSel(new Date());
    //await setMedicoSel("");
    await setEsitoSel("");
    await setNoteSel("");
    //await setLuogoSel("");
    await setTipologiaSel("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    /*if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
   
    */
    //qui devo caricare con la query i dati
  };

  const handleSave = async () => {
    let req = { ...required };
    let checkOk = true; //lo uso per controllare che tutto sia ok
    if (medicoSel === "" || medicoSel === null) {
      req.medico = true;
      checkOk = false;
    } else {
      req.medico = false;
    }
    if (tipologiaSel === "" || tipologiaSel === null) {
      req.tipo = true;
      checkOk = false;
    } else {
      req.tipo = false;
    }

    if (dataSel === "" || dataSel === null) {
      req.data = true;
      checkOk = false;
    } else {
      req.data = false;
    }

    setRequired(req);
    if (checkOk) {
      try {
        await unsplash.post(
          "visiteMediche",
          {
            accertamenti: selectionModel,
            medico: medicoSel,
            cliente: props.cliente,
            mansione: props.lavoratori[activeStep].Mansione,
            data: moment(dataSel).format("YYYY-MM-DD"),
            esito: esitoSel,
            luogo: luogoSel,
            note: noteSel,
            tipoVisita: tipologiaSel,
            lavoratore: props.lavoratori[activeStep].ID,
            vecchiaVisita: props.lavoratori[activeStep].IDUltimaVisita,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        setSnack({
          severity: "success",
          messaggio: "Visita Medica inserita con successo",
        });
        setOpenSnack(true);
        setOpen(false);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'inserimento",
        });
        setOpenSnack(true);
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Button
        variant="outlined"
        sx={{ display: "none" }}
        onClick={handleClickOpen}
        id="myDialog"
      >
        Open form dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <DialogTitle>Visita Massiva</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {props.lavoratori.map((lav, index) => {
                const stepProps = {};
                const labelProps = {};
                stepProps.completed = false;
                //if (isStepSkipped(index)) {

                //}
                return (
                  <Step
                    key={lav.ID}
                    {...stepProps}
                    sx={{
                      display:
                        props.lavoratori[activeStep]?.ID === lav.ID
                          ? "Block"
                          : "None",
                    }}
                  >
                    <StepLabel {...labelProps}>
                      {lav.Cognome + " " + lav.Nome + " - " + lav.Descrizione}{" "}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === props.lavoratori.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Visita Massive completate!
                </Typography>
                {/*<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>ESCI</Button>
      </Box> pulsante esci al momento non ci serve*/}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {required.tipo || required.data || required.medico ? (
                        <p style={{ color: "#FF2768" }}>
                          controlla i campi obbligatori!
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Medico
                        </InputLabel>
                        <Select
                          error={required.medico}
                          helperText={
                            required.medico ? "Medico obbligatorio" : ""
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={medicoSel}
                          label="Medico"
                          onChange={(e) => setMedicoSel(e.target.value)}
                        >
                          {props.medici?.map((medico) => (
                            <MenuItem key={medico.ID} value={medico.ID}>
                              {medico.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={DateAdapter}>
                        <DateTimePicker
                          ampmInClock={false}
                          renderInput={(props) => (
                            <TextField {...props} error={required.data} />
                          )}
                          label="Data e ora"
                          value={dataSel}
                          onChange={(newValue) => {
                            setDataSel(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Tipologia Visita
                        </InputLabel>
                        <Select
                          error={required.tipo}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tipologiaSel}
                          label="Tipologia Visita"
                          helperText={
                            required.data ? "Tipologia obbligatoria" : ""
                          }
                          onChange={(e) => setTipologiaSel(e.target.value)}
                        >
                          {props.tipologiaVisita?.map((tipologia) => (
                            <MenuItem key={tipologia.ID} value={tipologia.ID}>
                              {tipologia.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGridPro
                          disableSelectionOnClick
                          checkboxSelection
                          disableColumnMenu={true}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterPersonalized,
                          }}
                          slots={{
                            loadingOverlay: LinearProgress,
                          }}
                          loading={loading}
                          rows={accertamenti}
                          getRowId={(accertamenti) => accertamenti.ID}
                          columns={columns}
                          selectionModel={selectionModel?.map((sel) => sel.ID)}
                          onSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = accertamenti.filter((row) =>
                              selectedIDs.has(row.ID)
                            );

                            setSelectionModel(selectedRowData);
                          }}
                          onCellEditCommit={(params, event) => {
                            let row = { ...params.row };
                            if (params.field === "Scadenza") {
                              row[params.field] = moment(params.value).format(
                                "YYYY-MM-DD"
                              );
                            } else {
                              row[params.field] = params.value;
                            }

                            let selAcc = [...selectionModel];
                            let findIndex = selAcc.findIndex(
                              (acc) => acc.ID == params.row.ID
                            ); //trovo la posizione
                            if (findIndex !== -1) {
                              selAcc.splice(findIndex, 1);
                            }
                            selAcc.push(row);
                            setSelectionModel(selAcc);
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Esito
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={esitoSel}
                          label="Esito"
                          onChange={(e) => setEsitoSel(e.target.value)}

                          //onChange={handleChange}
                        >
                          {props.esiti?.map((esito) => (
                            <MenuItem key={esito.ID} value={esito.ID}>
                              {esito.Descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Luogo"
                        multiline
                        value={luogoSel}
                        onChange={(e) => setLuogoSel(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Note"
                        multiline
                        value={noteSel}
                        onChange={(e) => setNoteSel(e.target.value)}
                        sx={{ marginTop: "20px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid
            xs={12}
            item
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              px: 2,
            }}
            fullWidth
          >
            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Close />}
              onClick={handleClose}
              color="error"
            >
              Esci
            </Button>

            {/* <Button onClick={handleNext}>
              {activeStep === props.lavoratori.length - 1 ? "Fine" : "Avanti"}
            </Button> */}
            {activeStep === props.lavoratori.length - 1 ? (
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<DoneAll />}
                onClick={handleNext}
                color="success"
              >
                Fine
              </Button>
            ) : (
              <Button
                variant="contained"
                className="ButtonMiller"
                startIcon={<ArrowForward />}
                onClick={handleNext}
                color="success"
              >
                Avanti
              </Button>
            )}

            <Button
              variant="contained"
              className="ButtonMiller"
              startIcon={<Check />}
              onClick={handleSave}
              color="success"
            >
              Salva
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
